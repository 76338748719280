import { CloudFrameDefaultHeader, IHeaderConfig } from "@cloudframe/header";
import { ILayoutView } from "./layout-view.types";
import { DefaultPageFooterComponent } from "@cloudframe/page-footer";

const headerConfig = [
  // {
  //   key: "home",
  //   title: "home",
  //   url: "/",
  //   icon: "home",
  // },
  // {
  //   key: "dashboard",
  //   title: "dashboard",
  //   url: "/dashboard",
  //   icon: "ViewDashboard",
  // },
  // {
  //   key: "user-role",
  //   title: "user-role",
  //   url: "/user-role",
  //   icon: "WorkforceManagement",
  // },
  // {
  //   key: "profile",
  //   title: "profile",
  //   icon: "Contact",
  //   url: "#",
  //   items: [
  //     {
  //       key: "logout",
  //       title: "logout",
  //       url: "/logout",
  //       icon: "SignOut",
  //       iconStyle: {
  //         transform: "scale(1.1)",
  //       },
  //       onClick: () => {
  //         // pca.logout(loginRequest).then(() => {
  //         //   console.log("Logout!");
  //         // });
  //       },
  //     },
  //   ],
  //   iconStyle: {
  //     background: "#fff",
  //     color: "blue",
  //     borderRadius: "50%",
  //     transform: "scale(1.15)",
  //     marginRight: ".5rem",
  //   },
  // },
  {
    key: "logout",
    title: "logout",
    url: "/logout",
    icon: "",
    iconStyle: {
      transform: "scale(1.1)",
    },
    onClick: () => {
      // pca.logout(loginRequest).then(() => {})
      window.sessionStorage.clear();
      window.location.href = window.location.origin;
    },
  },
] as Array<IHeaderConfig>;

const LayoutView = (props: ILayoutView) => {
  const { children } = props;

  return (
    <div className="flex flex-col h-[100vh]">
      {/* <div className="fixed z-40 w-full">
        <CloudFrameDefaultHeader
          id="header"
          title="Dependency Viewer"
          headerConfig={headerConfig}
          headerCustomItems={
            <div>
              <button
                onClick={() => {
                  window.location.href = window.location.origin;
                }}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              >
                Logout
              </button>
            </div>
          }
          linkComponent={(props: any) => <a {...props} />}
          // backLink={{
          //   label: "Go Back",
          //   onClick: () => {
          //     window.location.href = window.location.origin;
          //   },
          //   href: "javascript:void(0);",
          //   style: {
          //     background: "transparent",
          //     color: "white",
          //     marginRight: "0rem",
          //     borderRadius: "2px",
          //     paddingTop: ".25rem",
          //     paddingBottom: ".25rem",
          //     fontWeight: "500",
          //     fontSize: "15px",
          //   },
          // }}
        />
      </div> */}
      <div className="flex-grow pb-28">{children}</div>
      <div id="footer" className="fixed bottom-0 w-full">
        <DefaultPageFooterComponent
          textCloudForm="CloudFrame"
          textCopyRight="@Copyright 2023"
        />
      </div>
    </div>
  );
};

export { LayoutView };
