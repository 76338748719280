import * as React from "react";
import { SVGProps } from "react";
import { appColors } from "../../styles/colors";
const SvgSql = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={appColors.blueBase}
      d="M19.48 4.54A19.17 19.17 0 0 0 12 2.75a19.17 19.17 0 0 0-7.48 1.79 1.74 1.74 0 0 0-1 1.59v11.74a1.74 1.74 0 0 0 1 1.59A19.169 19.169 0 0 0 12 21.25a19.169 19.169 0 0 0 7.48-1.79 1.74 1.74 0 0 0 1-1.59V6.13a1.74 1.74 0 0 0-1-1.59Zm-.48 8a17.76 17.76 0 0 1-7 1.72 17.76 17.76 0 0 1-7-1.72V8.68a18.641 18.641 0 0 0 7 1.57 18.641 18.641 0 0 0 7-1.57v3.86ZM5.15 5.9A17.56 17.56 0 0 1 12 4.25a17.67 17.67 0 0 1 6.86 1.65.26.26 0 0 1 .14.23V7a17.76 17.76 0 0 1-7 1.72A17.76 17.76 0 0 1 5 7v-.9a.25.25 0 0 1 .15-.2Zm13.7 12.2A17.561 17.561 0 0 1 12 19.75a17.67 17.67 0 0 1-6.86-1.65.26.26 0 0 1-.14-.23v-3.69a18.642 18.642 0 0 0 7 1.57 18.642 18.642 0 0 0 7-1.57v3.69a.25.25 0 0 1-.15.23Z"
    />
  </svg>
);
export default SvgSql;
