import { appColors } from "../../styles/colors";

const supportedCEEs = [
  "CEEGMTO",
  "CEELOCT",
  "CEEDATE",
  "CEEDYWK",
  "CEEGMT",
  "CEEDATM",
  "CEERANO",
  "CEEDAYS",
  "CEE3DLY",
  "CEEDLYM",
  "CEE3ABD",
];

const unSupportedModules = ["DBNTRY", "SQLADR", "SQLBEX", "ISPLINK"];

export const getCardColors = (
  programName: string,
  driverProgramName: string,
  isRecursive?: boolean,
  isDynamic?: boolean,
  isMissingProgram?: boolean,
  isDriverProgram?: boolean,
  isSupported?: boolean
) => {
  let backgroundColor = appColors.grey20;
  let textColor = appColors.black;

  if (isSupported === false) {
    backgroundColor = appColors.redOnLight;
    textColor = appColors.white;
  } else if (isSupported === undefined) {
    backgroundColor = appColors.pinkBase;
  } else if (isMissingProgram) {
    backgroundColor = appColors.pinkBase;
  } else if (isDynamic) {
    backgroundColor = appColors.yellowBase;
  } else if (driverProgramName === programName || isDriverProgram) {
    backgroundColor = appColors.greenSmooth;
  } else if (programName.startsWith("CEE")) {
    textColor = supportedCEEs.includes(programName)
      ? appColors.white
      : appColors.black;
    backgroundColor = supportedCEEs.includes(programName)
      ? appColors.darkGrey
      : appColors.orangeBase;
  } else if (unSupportedModules.includes(programName)) {
    backgroundColor = appColors.redOnLight;
    textColor = appColors.white;
  } else if (programName.startsWith("CSQ")) {
    backgroundColor = appColors.brownBase;
    textColor = appColors.white;
  } else if (programName.startsWith("BPX")) {
    textColor = appColors.black;
    backgroundColor = appColors.purpleSmoothLight;
  } else if (programName.startsWith("IRX")) {
    textColor = appColors.white;
    backgroundColor = "#3a86ff" || appColors.purpleSmoothLight;
  } else if (isRecursive) {
    backgroundColor = appColors.redSmooth;
    textColor = appColors.black;
  }

  return { backgroundColor, textColor };
};
