import * as React from "react";
import { appColors } from "../../styles/colors";
const SvgCopybook2 = (props: any) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 24 24"
    data-name="025_SCIENCE"
    id="_025_SCIENCE"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <defs>
      <style>{`.cls-1{fill:${appColors.blueBase};}`}</style>
    </defs>
    <path
      className="cls-1"
      d="M20,22H5a1,1,0,0,1-1-1V3A1,1,0,0,1,5,2H20a1,1,0,0,1,1,1V18a1,1,0,0,1-2,0V4H6V20H20a1,1,0,0,1,0,2Z"
    />
    <path className="cls-1" d="M7,7H3A1,1,0,0,1,3,5H7A1,1,0,0,1,7,7Z" />
    <path className="cls-1" d="M7,11H3A1,1,0,0,1,3,9H7a1,1,0,0,1,0,2Z" />
    <path className="cls-1" d="M7,15H3a1,1,0,0,1,0-2H7a1,1,0,0,1,0,2Z" />
    <path className="cls-1" d="M7,19H3a1,1,0,0,1,0-2H7a1,1,0,0,1,0,2Z" />
    <path className="cls-1" d="M15,11H11a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z" />
    <path className="cls-1" d="M15,15H11a1,1,0,0,1,0-2h4a1,1,0,0,1,0,2Z" />
  </svg>
);
export default SvgCopybook2;
