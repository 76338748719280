import { SVGProps } from "react";
import { appColors } from "../../styles/colors";
const SvgLineOfControl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      stroke={appColors.blueBase}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M8 16v-5m4 5V8m4 8v-2m2-10H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
    />
  </svg>
);
export default SvgLineOfControl;
