import { appColors } from "../../../styles/colors";

const JobCard = ({ name }: { name: string }) => {
  return (
    <div
      className="w-full h-full"
      style={{ background: appColors.orangeSmooth }}
    >
      <div className="p-4 ">
        <h2
          className="m-0 p-0 px-4 max-w-[90%] my-6 text-center"
          style={{ lineHeight: "2rem", fontSize: "3rem", color: "black" }}
        >
          {name}
        </h2>
        <hr className="my-4" />
        <div
          className="px-4 py-2 rounded-xl max-w-sm text-center text-xl mx-auto"
          style={{
            background: appColors.orangeSmoothLight,
            fontWeight: "bold",
            color: "black",
          }}
        >
          JOB
        </div>
      </div>
    </div>
  );
};

export default JobCard;
