import { CloudFrameDialog } from "@cloudframe/dialog";
import React, { useState } from "react";

export const DialogsComponent = React.forwardRef((props: any, ref: any) => {
  const [infoDialog, setInfoDialog] = useState({ title: "", subText: "" });
  const [showResetConfirmation, setShowResetConfirmation] = useState(false);

  if (ref) {
    ref.current = { setInfoDialog, setShowResetConfirmation };
  }

  return (
    <>
      <CloudFrameDialog
        hidden={!infoDialog.title}
        onDismiss={() => {
          setInfoDialog({ title: "", subText: "" });
        }}
        dialogContentProps={{
          title: infoDialog.title,
          subText: infoDialog.subText,
        }}
        primaryButton={{
          text: "Okay",
          onClick: () => {
            setInfoDialog({ title: "", subText: "" });
          },
        }}
      />
      <CloudFrameDialog
        hidden={!showResetConfirmation}
        onDismiss={() => {
          setShowResetConfirmation(false);
        }}
        dialogContentProps={{
          title: "Reset Artifacts",
          subText:
            "Are you sure you want to reset the artifacts? This action will remove all of the artifact files that have been uploaded",
        }}
        primaryButton={{
          text: "Reset",
          onClick: () => {
            setShowResetConfirmation(false);
            props.handleClear();
            props.setSelectedProgramId("");
          },
        }}
        secondaryButton={{
          text: "Cancel",
          onClick: () => {
            setShowResetConfirmation(false);
          },
        }}
      />
    </>
  );
});
