import { useState } from "react";
import SvgLeftArrow from "../../assets/left-arrow.svg";
import { appColors } from "../../styles/colors";

const GraphLegend = ({ metadata }: { metadata: any }) => {
  const [showLegend, setShowLegend] = useState(true);

  return (
    <div
      className="fixed bg-white top-[8rem] right-0 p-6"
      style={{ background: "#F9F9FA" }}
    >
      <div
        className="relative cursor-pointer"
        onClick={() => setShowLegend(!showLegend)}
      >
        <img
          src={SvgLeftArrow}
          alt="slider"
          className=" absolute -left-[2.5rem] top-[4.85rem]"
          style={{
            transform: showLegend ? "rotate(180deg)" : "none",
          }}
        />
      </div>
      {showLegend && (
        <div>
          <h4>
            {metadata?.graphType ? metadata?.graphType : "Program"} Color Codes
          </h4>
          <hr className="my-3 border-gray-100 opacity-50" />
        </div>
      )}
      {metadata?.legend &&
      Object.entries(metadata?.legend || "{}").length > 0 ? (
        Object.entries(metadata?.legend || "{}").map(([key, value], index) => (
          <>
            <div
              className="flex gap-4 justify-start items-center"
              style={{ marginTop: index === 0 ? 0 : "1rem" }}
            >
              <div
                className="h-[1rem] w-[1rem] rounded-full"
                style={{ backgroundColor: (value as any)?.nodeColor }}
              />{" "}
              <span className={`${showLegend ? "" : "hidden"}`}>{key}</span>
            </div>
          </>
        ))
      ) : (
        <>
          <div className="flex gap-4 justify-start items-center">
            <div className="h-[1rem] w-[1rem] bg-driver rounded-full" />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>
              Driver Program
            </span>
          </div>
          <div className="flex gap-4 justify-start items-center mt-4">
            <div
              style={{ background: appColors.pinkBase }}
              className="h-[1rem] w-[1rem] rounded-full"
            />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>
              Missing Program
            </span>
          </div>
          <div className="flex gap-4 justify-start items-center mt-4">
            <div className="h-[1rem] w-[1rem] bg-recursive rounded-full" />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>
              Recursive Program
            </span>
          </div>
          <div className="flex gap-4 justify-start items-center mt-4">
            <div className="h-[1rem] w-[1rem] bg-dynamic rounded-full" />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>
              {" "}
              Dynamic Call
            </span>
          </div>
          <div className="flex gap-4 justify-start items-center mt-4">
            <div
              style={{ background: appColors.brownBase }}
              className="h-[1rem] w-[1rem] rounded-full"
            />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>CSQ Modules</span>
          </div>
          <div className="flex gap-4 justify-start items-center mt-4">
            <div
              style={{ background: appColors.purpleSmoothLight }}
              className="h-[1rem] w-[1rem] rounded-full"
            />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>BPX Modules</span>
          </div>
          <div className="flex gap-4 justify-start items-center mt-4">
            <div
              style={{ background: "#3a86ff" }}
              className="h-[1rem] w-[1rem] rounded-full"
            />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>IRX Modules</span>
          </div>
          <div className="flex gap-4 justify-start items-center mt-4">
            <div
              style={{ background: appColors.darkGrey }}
              className="h-[1rem] w-[1rem] rounded-full"
            />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>
              Supported System Programs
            </span>
          </div>
          <div className="flex gap-4 justify-start items-center mt-4">
            <div
              style={{ background: appColors.orangeBase }}
              className="h-[1rem] w-[1rem] rounded-full"
            />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>
              Unsupported System Programs
            </span>
          </div>
          <div className="flex gap-4 justify-start items-center mt-4">
            <div
              style={{ background: appColors.redOnLight }}
              className="h-[1rem] w-[1rem] rounded-full"
            />{" "}
            <span className={`${showLegend ? "" : "hidden"}`}>
              Unsupported Modules
            </span>
          </div>
        </>
      )}
    </div>
  );
};

export { GraphLegend };
