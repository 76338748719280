export const appColors = {
  black: "rgba(21,27,35,1)",
  grey90: "rgba(38,46,58,1)",
  darkGrey: "rgba(55,66,82,1)",
  grey80: "rgba(87,96,110,1)",
  grey60: "rgba(141,151,164,1)",
  grey40: "rgba(189,198,209,1)",
  grey20: "rgba(216,222,230,1)",
  grey10: "rgba(230,235,241,1)",
  greyBG: "rgba(247,248,250,1)",
  greyLight: "rgb(238,241,242)",
  grey10Light: "rgba(216,222,230,1)",
  white: "rgba(255,255,255,1)",
  whiteLight: "rgba(249, 249, 249, 1)",
  redOnLight: "rgba(211,54,41,1)",
  redBase: "rgba(255,88,74,1)",
  redSmoothLight: "rgba(255,88,74,.4)",
  redSmooth: "rgba(255,215,212,1)",
  greenBase: "rgba(58,178,119,1)",
  greenSmoothLight: "rgba(58,178,119,.4)",
  greenOnLight: "rgba(52, 132, 92, 1)",
  greenSmooth: "rgba(197,232,215,1)",
  limegreenBase: "rgba(132,216,102,1)",
  limegreenSmoothLight: "rgba(132,216,102,.4)",
  limegreenSmooth: "rgba(219,244,210,1)",
  yellowBase: "rgba(252,216,135,1)",
  yellowSmoothLight: "rgba(255,203,87,.4)",
  yellowSmooth: "rgba(255,240,205,1)",
  whiteBase: "rgba(255,255,255,1)",
  whiteSmoothLight: "rgb(243,244,244)",
  whiteSmooth: "rgba(255,255,255,1)",
  orangeBase: "rgba(255,128,93,1)",
  orangeSmoothLight: "rgba(255,128,93,.4)",
  lightOrangeSmooth: "rgba(255,226,205,1)",
  lightOrangeSmoothLight: "rgba(243, 216, 197, 1)",
  orangeSmooth: "rgba(255,223,214,1)",
  orangeAction: "rgba(255,99,86,1)",
  brownBase: "rgba(122,82,78,1)",
  brownSmoothLight: "rgba(122,82,78,.4)",
  brownSmooth: "rgba(234,220,219,1)",
  darkBrownBase: "rgb(98,88,87)",
  darkBrownSmoothLight: "rgba(122,82,78,.4)",
  darkBrownSmooth: "rgb(196,181,179)",
  purpleBase: "rgba(89,86,233,1)",
  purpleSmoothLight: "rgba(89,86,233,.4)",
  purpleSmooth: "rgba(220,219,255,1)",
  blueBase: "rgba(52,107,225,1)",
  blueSmoothLight: "rgba(52,107,225,.4)",
  blueSmooth: "rgba(209,224,255,1)",
  blueDark: "rgba(55,66,82,1)",
  skyblueBase: "rgba(86,189,233,1)",
  skyblueSmoothLight: "rgba(86,189,233,.4)",
  skyblueSmooth: "rgba(205,236,249,1)",
  tealBase: "rgba(90,226,226,1)",
  tealSmoothLight: "rgba(90,226,226,.4)",
  tealSmooth: "rgba(206,247,247,1)",
  tealDarkBase: "rgb(56, 151, 151)",
  pinkBase: "rgba(252,119,160,1)",
  pinkSmoothLight: "rgb(237, 212, 222)",
  pinkSmooth: "rgb(248, 222, 232)",
  blueCardOutline: "rgb(87, 96, 110)",
  greyCardOutline: "rgb(216, 222, 230)",
  ddlGrey40: "rgba(206,206,206,1)",
  ddlGrey20: "rgba(239,239,244,0.94)",
  indigoBase: "rgb(77,93,128)",
  indigoSmoothLight: "rgb(148,175,234)",
  indigoSmooth: "rgb(148,175,234)",
  progressBarTrail: "rgb(86,96,110)",
};
