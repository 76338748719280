import React from "react";
import { Graphviz } from "graphviz-react";
import { convertToDot } from "./graph.service";
import { GraphLegend } from "../graph-legend/graph-legend";

export const GraphGraph = React.forwardRef(
  (
    props: {
      graphData: any;
      metadata: any;
      selectedProgramId: string;
      onClickNode: any;
      onClickLink: any;
    },
    graphRef
  ) => {
    return (
      <div>
        <GraphLegend metadata={props.metadata} />
        {props.graphData?.unsupported && (
          <div className="fixed bottom-[3.3rem] right-0 p-4 max-w-[10.5rem] bg-orange-200">
            <p>Unsupported modules found!</p>
          </div>
        )}
        <div className="min-w-screen">
          <Graphviz
            options={{
              width: window.innerWidth || 2200,
              height: window.innerHeight || 1000,
              zoom: true,
              fade: true,
              useWorker: true,
              fit: true,
            }}
            dot={convertToDot(
              props.graphData,
              props.selectedProgramId,
              props.metadata?.legend
            )}
            key={props.selectedProgramId}
          />
        </div>
      </div>
    );
  }
);
