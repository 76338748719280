import { Icon, Panel, PrimaryButton } from "@fluentui/react";
import { appColors } from "../../../styles/colors";
import { useBoolean } from "@fluentui/react-hooks";

const StepCard = ({ name, node }: { name: string; node: any }) => {
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);
  return (
    <div
      className="w-full h-full"
      style={{ backgroundColor: appColors.blueSmooth }}
    >
      <div className="p-4 ">
        <h2
          className="m-0 p-0 px-4 max-w-[90%] my-4 text-center"
          style={{ lineHeight: "2rem", fontSize: "2.5rem" }}
        >
          {name}
        </h2>
        <hr className="my-3" />
        <div
          className="px-4 py-2 rounded-xl max-w-sm text-center text-xl mx-auto"
          style={{ background: appColors.blueSmoothLight }}
        >
          STEP
        </div>

        <div className="text-center mt-3">
          <Icon
            onClick={(e) => {
              e.stopPropagation();
              openPanel();
            }}
            iconName="Info"
            style={{
              fontSize: 42,
              color: "white",
              background: "black",
              borderRadius: "50%",
            }}
          />
        </div>
      </div>
      <Panel
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
        headerText={name}
        isFooterAtBottom
        // onRenderFooterContent={() => (
        //   <div className="ml-2 bg-white py-4">
        //     <PrimaryButton onClick={dismissPanel}>Close</PrimaryButton>
        //   </div>
        // )}
      >
        <div className="mt-4">
          <h3>Datasets:</h3>
          <hr className="mt-2" />
          <div className="mt-4">
            {node?.datasets?.map((dd: any) => (
              <div
                className="p-4 mt-2 border border-sky-500 shadow-md rounded-sm"
                key={dd.ddName}
                style={{
                  borderWidth: 1,
                  background: "rgba(249, 249, 249, 1)" || appColors.ddlGrey20,
                }}
              >
                <div>
                  <div className="flex flex-row justify-between">
                    <p className="text-l font-bold text-gray-600">DD Name</p>
                    <p className="text-l font-bold">{dd.ddName}</p>
                  </div>
                  <div className="flex flex-row justify-between mt-1">
                    <p className="text-l font-bold text-gray-600">
                      Record Format
                    </p>
                    <p className="text-l font-bold">{dd.recordFormat}</p>
                  </div>
                </div>
                <hr className="my-3" />
                <p className="font-semibold mb-2">Dataset Names:</p>
                <div className=" break-words">
                  {dd.datasetNames.map((dataset: any) => (
                    <p className="text-l" key={dataset.name}>
                      {dataset.name}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </Panel>
    </div>
  );
};

export default StepCard;
