import { appColors } from "../../styles/colors";
import { JobDataStructure } from "../../types/new-data.types";
import { getCardColors } from "../card/card.service";
import dagre from "dagre";

const unsupportedModules: string[] = ["DBNTRY", "SQLADR", "SQLBEX", "ISPLINK"];

function rgbaToHex(rgba: string) {
  const [r, g, b, a] = rgba.match(/\d+/g) as any;
  const hex = (x: any) => ("0" + parseInt(x).toString(16)).slice(-2);

  return `#${hex(r)}${hex(g)}${hex(b)}`;
}

export const convertToDot = (jsonData: any, selected: string, legend: any) => {
  const recursivePgrms: any = {};

  jsonData?.links?.forEach((link: any) => {
    if (link?.source === link?.target) {
      recursivePgrms[link.target] = true;
    }
  });

  let dotContent = "digraph MyGraph {\n ";
  dotContent += `node [ shape="plaintext" style="filled, rounded" fontname="Lato" margin=0.2 ]
    edge [ fontname="Lato" color="#2B303A" ]\n`;
  dotContent += `splines=true;\n`;

  jsonData.nodes?.forEach((node: any) => {
    const isDynamic = !!jsonData.links?.some((link: any) => {
      return link.label === "Dynamic call" && link.target === node.id;
    });

    const { backgroundColor, textColor } =
      !!legend && Object.keys(legend || "{}").length > 0
        ? {
            backgroundColor: legend[node.legend]?.nodeColor || appColors.grey20,
            textColor: legend[node.legend]?.textColor || "black",
          }
        : getCardColors(
            node.id,
            selected,
            !!recursivePgrms[node.id],
            isDynamic,
            node.missingProgram,
            node.isDriverProgram,
            node.isSupported
          );

    if (node.id === selected && node.nodeType === "job") {
      dotContent += `  ${node.id
        .replaceAll("-", "_")
        .replaceAll(" ", "_")} [label=<
        <TABLE BORDER="0" CELLBORDER="0" CELLSPACING="0">
            <TR>
                <TD ALIGN="CENTER">${node.id}</TD>
            </TR>
            <TR>
                <TD ALIGN="CENTER"><FONT POINT-SIZE="10">Job</FONT></TD>
            </TR>
        </TABLE>
    > fillcolor="${rgbaToHex(appColors.orangeSmooth)}" fontcolor="black"];\n`;
    } else if (node.nodeType === "step") {
      dotContent += `  ${node.id
        .replaceAll("-", "_")
        .replaceAll(" ", "_")} [label=<
        <TABLE BORDER="0" CELLBORDER="0" CELLSPACING="0">
            <TR>
                <TD ALIGN="CENTER">${node.id}</TD>
            </TR>
            <TR>
                <TD ALIGN="CENTER"><FONT POINT-SIZE="10">Step</FONT></TD>
            </TR>
        </TABLE>
    > fillcolor="${rgbaToHex(appColors.blueSmooth)}" fontcolor="black"];\n`;
    } else {
      dotContent += `  ${node.id
        .replaceAll("-", "_")
        .replaceAll(" ", "_")} [label="${node.id
        .replaceAll("-", "_")
        .replaceAll(" ", "_")}" fillcolor="${
        backgroundColor.startsWith("rgb")
          ? rgbaToHex(backgroundColor)
          : backgroundColor
      }" fontcolor="${
        textColor.startsWith("rgb") ? rgbaToHex(textColor) : textColor
      }"];\n`;
    }
  });

  jsonData.links.forEach((link: any) => {
    dotContent += `  ${link.source
      .replaceAll("-", "_")
      .replaceAll(" ", "_")} -> ${link.target
      .replaceAll("-", "_")
      .replaceAll(" ", "_")};\n`;
  });

  // Close DOT content
  dotContent += "}";
  return dotContent;
};

export const filterUnconnectedNodes = (
  nodes: any[],
  links: any[],
  rootId: string
) => {
  const connectedNodes = new Set<string>();
  const queue: string[] = [rootId];

  // Perform BFS (Breadth-First Search) starting from the root node
  while (queue.length > 0) {
    const currentNodeId = queue.shift() as string;
    connectedNodes.add(currentNodeId);

    links.forEach((link) => {
      if (link.source === currentNodeId && !connectedNodes.has(link.target)) {
        connectedNodes.add(link.target);
        queue.push(link.target);
      }
      // if (link.target === currentNodeId && !connectedNodes.has(link.source)) {
      //   connectedNodes.add(link.source);
      //   queue.push(link.source);
      // }
    });
  }

  // Filter unconnected nodes and their links
  const filteredNodes = nodes.filter((node) => connectedNodes.has(node.id));
  const filteredLinks = links.filter(
    (link) => connectedNodes.has(link.source) && connectedNodes.has(link.target)
  );

  return { nodes: filteredNodes, links: filteredLinks };
};

export const convertToExecutionFlowGraph = (jsonData: JobDataStructure) => {
  let nodes: any = [];
  const links: any = [];
  let jobName = "";

  jsonData.forEach((item) => {
    if (item.type === "job") {
      nodes.push({
        id: item.id,
        nodeType: "job",
        size: { width: 5000, height: 2000 },
      });
      jobName = item.id;

      item.links.forEach((step) => {
        nodes.push({
          id: step.id,
          nodeType: "step",
          parm: step.parm,
          datasets: step.datasets,
          condition: step.condition,
          size: { width: 4400, height: 2000 },
        });
        links.push({
          source: item.id,
          target: step.id,
        });

        if (step.links) {
          nodes.push({
            id: step.links.id,
            nodeType: "program",
            isDriverProgram: true,
          });
          links.push({
            source: step.id,
            target: step.links.id,
          });
        }
      });
    } else {
      item.program.forEach((program) => {
        const { ProgramName, links: calls, ...rest } = program;
        const progNode = nodes.find((n: any) => n.id === program.id);

        if (
          program.id.toUpperCase().startsWith("DSN") ||
          program.id.toUpperCase().startsWith("DFH")
        ) {
          return;
        }

        const nodeData = {
          ...rest,
        };

        if (progNode) {
          nodes = nodes.map((n: any) =>
            n.id === program.id ? { ...progNode, ...nodeData } : n
          );
        } else {
          nodes.push(nodeData);
        }

        calls?.forEach((call) => {
          const calledProgram = nodes.find((n: any) => n.id === call.id);

          if (
            call.id.toUpperCase().startsWith("DSN") ||
            call.id.toUpperCase().startsWith("DFH")
          ) {
            return;
          }

          if (!calledProgram) {
            nodes.push({
              id: call.id,
              nodeType: "program",
            });
          }

          links.push({
            source: program.id,
            target: call.id,
          });
        });
      });
    }
  });
  const { nodes: filteredNodes, links: filteredLinks } = filterUnconnectedNodes(
    nodes,
    links,
    jobName
  );
  const { layoutedNodes, layoutedEdges } = getLayoutedElements(
    filteredNodes,
    filteredLinks
  );

  let unsupportedModulesFound = nodes.some((node: { id: string }) =>
    unsupportedModules.includes(node.id.toUpperCase())
  );

  return {
    unsupported: unsupportedModulesFound,
    nodes: layoutedNodes,
    links: layoutedEdges,
  };
};

export const getLayoutedElements = (canvasNodes: any[], canvasEdges: any[]) => {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  const nodeWidth = 470;
  const nodeHeight = 207;

  dagreGraph.setGraph({
    rankdir: "TB",
    // align: "DL",
    edgesep: 50,
    ranksep: 100,
  });

  canvasNodes.forEach((node) => {
    dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
  });

  canvasEdges.forEach((edge) => {
    dagreGraph.setEdge(edge.source, edge.target);
  });

  dagre.layout(dagreGraph);

  const layoutedNodes = canvasNodes.map((node) => {
    const newNode = { ...node };
    const nodeWithPosition = dagreGraph.node(node.id);

    newNode.fx = nodeWithPosition.x - nodeWidth / 2;
    newNode.fy = nodeWithPosition.y - nodeHeight / 2;

    // newNode.position = {
    //   x: nodeWithPosition.x - nodeWidth / 2,
    //   y: nodeWithPosition.y - nodeHeight / 2,
    // };

    return newNode;
  });

  return { layoutedNodes, layoutedEdges: canvasEdges };
};
