import * as React from "react";
import { SVGProps } from "react";
import { appColors } from "../../styles/colors";
const SvgCisc = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill={appColors.blueBase}
      d="M16 16h3a3 3 0 1 1-3 3.001V16ZM5 16l3 .001v3a3 3 0 1 1-3-3Z"
    />
    <path
      fill={appColors.blueBase}
      fillRule="evenodd"
      d="M19 8h-3V5a3 3 0 1 1 3 3ZM8 8V5a3 3 0 1 0-3 3h3Z"
      clipRule="evenodd"
    />
    <path fill={appColors.blueBase} d="M16 8H8v8h8V8Z" opacity={0.5} />
  </svg>
);
export default SvgCisc;
