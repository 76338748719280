import React from "react";
import { Graph } from "../graph";
import { Card } from "../card";
import { GraphLegend } from "../graph-legend/graph-legend";
import { getLayoutedElements } from "./graph.service";

const myConfig = {
  nodeHighlightBehavior: true,
  node: {
    color: "lightgreen",
    size: 120,
    highlightStrokeColor: "blue",
  },
  link: {
    highlightColor: "lightblue",
  },
};

export const DepGraph = React.memo(
  React.forwardRef(
    (
      props: {
        graphData: any;
        selectedProgramId: string;
        onClickNode: any;
        onClickLink: any;
        metadata: any;
      },
      graphRef
    ) => {
      const recursivePgrms: any = {};

      props.graphData?.links.forEach((link: any) => {
        if (link.source === link.target) {
          recursivePgrms[link.target] = true;
        }
      });

      const graphD = {
        nodes: props.graphData.nodes.map((node: any) => ({
          ...node,
          x: NaN,
          y: NaN,
        })),
        links: props.graphData.links,
      };

      const { layoutedNodes, layoutedEdges } = getLayoutedElements(
        graphD.nodes,
        graphD.links
      );

      return (
        <div>
          <GraphLegend metadata={props.metadata} />
          {props.graphData?.unsupported && (
            <div className="fixed bottom-[3.3rem] right-0 p-4 max-w-[10.5rem] bg-orange-200">
              <p>Unsupported modules found!</p>
            </div>
          )}
          <Graph
            ref={graphRef as any}
            id="graph-id" // id is mandatory
            // data={grData}
            data={{
              nodes: layoutedNodes,
              links: layoutedEdges,
            }}
            config={{
              ...myConfig,
              highlightOpacity: 0.3,
              initialZoom: 0.3,
              width: window.innerWidth,
              height: window.innerHeight - 200,
              directed: true,
              d3: {
                linkLength: 1000,
                alphaTarget: 0.05,
                gravity: -250,
                linkStrength: 1,
              },
              staticGraphWithDragAndDrop: true,
              node: {
                renderLabel: false,
                size: {
                  width: 3700,
                  height: 1380,
                },
                highlightStrokeColor: "blue",
                viewGenerator: (prop: any) => {
                  const isDyanmic = !!props.graphData.links?.some(
                    (link: any) => {
                      return (
                        link.label === "Dynamic call" && link.target === prop.id
                      );
                    }
                  );

                  return (
                    <Card
                      id={prop.id}
                      recursive={recursivePgrms[prop.id]}
                      selectedProgramId={props.selectedProgramId}
                      name={prop.id}
                      dynamicCall={isDyanmic}
                      highlighted={prop.highlighted}
                      isMissingProgram={prop.missingProgram}
                      isDriverProgram={prop.isDriverProgram}
                      metadata={props.metadata}
                      rest={prop}
                    />
                  );
                },
              },
              link: {
                color: "rgb(171,174,176)",
                highlightColor: "black" || "rgb(140,236,254)",
                strokeWidth: 2.5,
                labelProperty: "label",
                labelColor: "red",
                renderLabel: true,
                fontColor: "#fff",
                fontSize: 16,
                fontWeight: 600,
                disableForced: true,
              },
            }}
            onClickNode={props.onClickNode}
            onClickLink={props.onClickLink}
          />
        </div>
      );
    }
  )
);
