import './App.css';
import { DependencyGraph, LayoutView } from './components';

function App() {
  return (
    <LayoutView>
      <main>
        <DependencyGraph />
      </main>
    </LayoutView>
  );
}

export default App;
