import { CloudFrameDefaultHeader } from "@cloudframe/header";
import { IDropdownOption, Icon } from "@fluentui/react";
import * as d3 from "d3";
import { saveAs } from "file-saver";
import { useEffect, useRef, useState } from "react";
import Viz from "viz.js";
import { Module, render } from "viz.js/full.render.js";
import { useArtifactUpload } from "../../hooks/useArtifactUpload";
import { SharedService } from "../../services/shared-service";
import { appColors } from "../../styles/colors";
import { ChartControls } from "../chart-controls";
import { SideBar } from "../sidebar";
import { Switcher } from "../switch";
import { DepGraph } from "./dep-graph";
import { DialogsComponent } from "./dialogs";
import {
  convertToDot,
  convertToExecutionFlowGraph,
  filterUnconnectedNodes,
} from "./graph.service";
import { GraphGraph } from "./graviz-graph";
import { CloudFrameDropdown } from "@cloudframe/dropdown";
import { folder } from "jszip";

const packageVersion = require("../../../package.json")["scanner-version"];

const colorLegendData = [
  { color: "#C5E8D7", label: "Driver Program" },
  { color: appColors.pinkBase, label: "Missing Program" },
  { color: "#FFD7D4", label: "Recursive Program" },
  { color: "rgba(252,216,135,1)", label: "Dynamic Call" },
  { color: appColors.brownBase, label: "CSQ Modules" },
  { color: appColors.purpleSmoothLight, label: " BPX Modules" },
  { color: "#3a86ff", label: "IRX Modules" },
  { color: appColors.darkGrey, label: "Supported System Programs" },
  { color: appColors.orangeBase, label: " Unsupported System Programs" },
  { color: appColors.redOnLight, label: " Unsupported Modules" },
];

export const DependencyGraph = () => {
  const [selectedProgramId, setSelectedProgramId] = useState("");

  const storedFolderNamesString = sessionStorage.getItem("storedFolderName");
  const graphRef = useRef<any>(null);
  const [folderOptions, setFolderOptions] = useState<IDropdownOption[]>([]);
  const [graphViz, setGraphViz] = useState(false);
  const [artifactIds, setArtifactIds] = useState<string[]>([]);
  const [artifacts, setArtifacts] = useState<any[]>([]);
  const ref = useRef<any>(null);
  const [folderOption, setFolderOption] = useState("");
  const [programs, setPrograms] = useState<any>([]);
  const [programIds, setProgramIds] = useState<string[]>([]);
  const [metadata, setMetadata] = useState(null);
  const extractedIds: string[] = [];
  const {
    // artifacts,
    // artifactIds,
    // programs,
    // programIds,
    // metadata,
    confData,
    handleUploadZip,
    handleUploadJson,
    handleConfClear,
  } = useArtifactUpload();
  const isProgramSelected = programIds.includes(selectedProgramId);
  const currentIndex = (isProgramSelected ? programIds : artifactIds)?.findIndex(
    (item) => item === selectedProgramId
  );
  useEffect(() => {
    if (folderOptions.length > 0) {
      setFolderOption(folderOptions[0].text);
    }
  }, [folderOptions]);
  useEffect(() => {
    if (confData && folderOption && confData.hasOwnProperty(folderOption)) {
      const mData = (confData as any)[folderOption].metadata;
      setMetadata(mData);
      const artifactIdsArray: string[] = (confData as any)[
        folderOption
      ].jcl?.map(((obj: any) => obj.id));
      if (artifactIdsArray)
        setArtifactIds(artifactIdsArray)

      const artifact = (confData as any)[folderOption].artifacts;
      if (artifact)
        setArtifacts(artifact);

      const programIdsArray: string[] = (confData as any)[
        folderOption
      ].program?.map((obj: any) => JSON.stringify(obj));
      convertProgramToNodesAndLinks(programIdsArray);
    }
  }, [folderOption, confData]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      graphRef.current?.restartSimulation();
    }, 300);

    return () => clearTimeout(timeout);
  }, []);
  useEffect(() => {
    if (!selectedProgramId) {
      const sortedArtifactsIds = [...artifactIds].sort();
      const sortedProgramIds = [...programIds].sort();
      setSelectedProgramId(sortedArtifactsIds[0] || sortedProgramIds[0] || "");
    }
  }, [selectedProgramId, artifactIds, programIds]);

  useEffect(() => {
    if (graphRef.current) {
      setTimeout(centerGraph, 0);
    }
  }, [selectedProgramId]);
  useEffect(() => {
    if (storedFolderNamesString) {
      const storedFolderNames = JSON.parse(storedFolderNamesString) as string[];
      let newFolderOptions: IDropdownOption[] = [];
      newFolderOptions = storedFolderNames.map((folderName, index) => ({
        key: index.toString(),
        text: folderName === "Program" ? "Program Execution Flow" : folderName,
      }));
      setFolderOptions(newFolderOptions);
      // setFolderOption((folderOptions[0].key as string))
    }
  }, [storedFolderNamesString]);
  function centerGraph() {
    const ref = graphRef as any;
    const wrapper = d3.select(`#${ref.current.state.id}-graph-wrapper`);

    const gElement = document.getElementById(
      `${ref.current.state.id}-graph-container-zoomable`
    );

    if (gElement && gElement.childNodes.length < 6) {
      return;
    }

    const zoom: any = d3
      .zoom()
      .scaleExtent([
        ref.current.state.config.minZoom,
        ref.current.state.config.maxZoom,
      ]);

    const svg = d3.select(`svg`);
    const g = d3.select(`#${ref.current.state.id}-graph-container-zoomable`);

    zoom.on("zoom", () => {
      g.attr("transform", d3.event.transform);
    });
    wrapper.call(zoom as any).on("dblclick.zoom", null);

    const bbox = (g as any)?.node()?.getBBox();
    const width = ref.current.state.config.width - 100;
    const height = ref.current.state.config.height;

    const scale = Math.min(width / bbox.width, height / bbox.height);
    const translateX = (width - bbox.width * scale) / 2 - bbox.x * scale;
    const translateY = (height - bbox.height * scale) / 2 - bbox.y * scale;

    const transform = d3.zoomIdentity
      .translate(translateX, translateY)
      .scale(scale);

    svg.on(".zoom", null);
    svg.transition().duration(750).call(zoom.transform, transform);
    svg.call(zoom);
  }
  function convertProgramToNodesAndLinks(data: any) {
    const nodes: any[] = [];
    const links: any[] = [];
    let ids: string[] = [];
    // console.log(data)
    data.forEach((item: any) => {
      // console.log(item)
      item = JSON.parse(item);
      const node = { id: item.id, ...item };
      nodes.push(node);
      if (item.isRootNode) {
        ids.push(item.id);
      }

      if (item.links) {
        item.links?.forEach((link: { id: string }) => {
          links.push({ source: item.id, target: link.id });
        });
      }
    });

    links.forEach((link) => {
      const { source, target } = link;
      if (!nodes.some((n) => n.id === source)) {
        nodes.push({ id: source, ProgramName: source });
      }
      if (!nodes.some((n) => n.id === target)) {
        nodes.push({ id: target, ProgramName: target });
      }
    });

    const result = ids?.map((x) => {
      return filterUnconnectedNodes(
        nodes.map((y) => (x === y.id ? { ...y, isDriverProgram: true } : y)),
        links,
        x
      );
    });

    setPrograms(result || []);
    setProgramIds(ids || []);
    sessionStorage.setItem("storedPrograms", JSON.stringify(result || []));
    sessionStorage.setItem("storedProgramIds", JSON.stringify(ids || []));
  }
  const handleClear = () => {
    setArtifacts([]);
    setArtifactIds([]);
    setPrograms([]);
    setProgramIds([]);
    handleConfClear();
    setFolderOptions([]);
    sessionStorage.removeItem("storedArtifacts");
    sessionStorage.removeItem("storedArtifactIds");
    sessionStorage.removeItem("selectedProgramId");
    sessionStorage.removeItem("storedPrograms");
    sessionStorage.removeItem("storedProgramIds");
    sessionStorage.removeItem("artifactsMetadata");
    sessionStorage.removeItem("storedFolderName");
    sessionStorage.removeItem("artifactConf");
  };
  const handleProgramChange = (programId: string) => {
    SharedService.setPrevTr(null);
    setSelectedProgramId(programId);
    sessionStorage.setItem("selectedProgramId", programId);
    // setGraphViz(false);
    setTimeout(() => {
      graphRef.current?.restartSimulation();
    }, 200);
  };

  const onClickNode = function (nodeId: any) {
    graphRef.current._setNodeHighlightedValue(nodeId, true);
  };

  const onClickLink = function (source: any, target: any) { };

  const handleClickUploadZip = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    try {
      const file = e.target.files?.[0];
      if (file) {
        await handleUploadZip(file);
        return ref.current?.setInfoDialog({
          title: "Artifacts uploaded",
          subText: "Artifacts uploaded successfully 👍",
        });
      }
    } catch (err: any) {
      console.log(err);
      ref.current?.setInfoDialog({
        title: "Failed to upload artifacts",
        subText: err?.message || "Please try again",
      });
    } finally {
      e.target.value = "";
    }
  };

  const addColorLegend = (svgString: string, legend: any): string => {
    const legendX = 20;
    let legendY = 23;
    const circleRadius = 8;
    const textOffset = 4;

    let legendData = colorLegendData;

    if (legend) {
      legendData = Object.entries(legend).map(([key, value]: any) => ({
        color: value?.nodeColor,
        label: key,
      }));
    }
    const result = svgString.match(/<polygon fill="#ffffff" [^>]*>/g);

    if (!result?.length) {
      throw new Error("No match found");
    }

    const svgResult = svgString.match(/<svg[^>]*>/g);

    if (!svgResult?.length) {
      throw new Error("No match found");
    }

    const height = svgString.match(/height="([^"]*)"/g);

    if (!height?.length) {
      throw new Error("No match found");
    }

    const heightValue = height[0].match(/\d+/g);

    if (!heightValue?.length) {
      throw new Error("No match found");
    }

    const newHeight = parseInt(heightValue[0]) + 700;

    const newSvgString = height[0].replace(
      heightValue[0],
      newHeight.toString()
    );

    let newSVGLine = svgResult[0].replace(height[0], newSvgString);

    const width = svgString.match(/width="([^"]*)"/g);

    if (!width?.length) {
      throw new Error("No match found");
    }

    const widthValue = width[0].match(/\d+/g);

    if (!widthValue?.length) {
      throw new Error("No match found");
    }
    const svgWidth = parseInt(widthValue[0]) + 1200;

    const svgWidthString = width[0].replace(widthValue[0], svgWidth.toString());
    const viewBoxMatch = svgString.match(/viewBox="([^"]*)"/);
    if (!viewBoxMatch) throw new Error("No match found for viewBox");
    const viewBoxValues = viewBoxMatch[1].split(" ");
    const newViewBoxWidth = svgWidth;
    const newViewBoxHeight = newHeight;
    const newViewBox = `viewBox="${viewBoxValues[0]} ${viewBoxValues[1]} ${newViewBoxWidth} ${newViewBoxHeight}"`;
    const viewBoxSVGLine = newSVGLine.replace(viewBoxMatch[0], newViewBox);
    const finalSVGLine = viewBoxSVGLine.replace(width[0], svgWidthString);
    // Generate the legend section
    let prevTextX: any = 0;
    const legendSection = legendData
      .map((data, index) => {
        let textX = index === 0 ? legendX : legendX + prevTextX;
        if (textX >= svgWidth) {
          textX = 0;
          legendY = 30;
        } else {
          prevTextX = textX + data.label.length * 6 + 10;
        }
        return `
      <circle cx="${textX + 5}" cy="${legendY - 3}" r="${circleRadius}" fill="${data.color
          }" />
      <text x="${textX + circleRadius + 10
          }" y="${legendY}" font-family="Lato" font-size="12" fill="#151b23">${data.label
          }</text>
    `;
      })
      .join("");
    const rightLegend =
      `<g id="color-coding-legend" transform="translate(0 20)">` +
      legendSection +
      `</g>`;
    let modifiedSvgString = svgString.replace(
      result[0],
      `${result[0]} ${rightLegend}`
    );

    modifiedSvgString = modifiedSvgString.replace(svgResult[0], finalSVGLine);
    return modifiedSvgString;
  };

  const downloadSvg = async (dotString: string, fileName: string = "graph") => {
    const viz = new Viz({ Module, render });

    try {
      const svgString = await viz.renderString(dotString, { format: "svg" });

      const modifiedString = addColorLegend(
        svgString,
        (metadata as any)?.legend
      ) as string;
      const blob = new Blob([modifiedString], { type: "image/svg+xml" });
      saveAs(blob, `${fileName}.svg`);
    } catch (error) {
      console.error("Error rendering graph:", error);
    }
  };

  const downloadSVG = () => {
    const graphData =
      currentIndex === -1
        ? { nodes: [], links: [] }
        : isProgramSelected
          ? programs[currentIndex]
          : convertToExecutionFlowGraph(artifacts[currentIndex]);

    downloadSvg(
      convertToDot(graphData, selectedProgramId, (metadata as any)?.legend)
    );
  };

  return (
    <div>
      <div className="z-40 w-full">
        <CloudFrameDefaultHeader
          id="header"
          title="Relation Viewer"
          // headerConfig={headerConfig}
          headerCustomItems={
            <div>
              <div className="text-center">
                <span className="px-4 py-2 bg-greenSmoothLight rounded-md">
                  Supported Scanner Version: <strong>{packageVersion}</strong>
                </span>
              </div>
            </div>
          }
          linkComponent={(props: any) => <a {...props} />}
        // backLink={{
        //   label: "Go Back",
        //   onClick: () => {
        //     window.location.href = window.location.origin;
        //   },
        //   href: "javascript:void(0);",
        //   style: {
        //     background: "transparent",
        //     color: "white",
        //     marginRight: "0rem",
        //     borderRadius: "2px",
        //     paddingTop: ".25rem",
        //     paddingBottom: ".25rem",
        //     fontWeight: "500",
        //     fontSize: "15px",
        //   },
        // }}
        />
      </div>
      <div>
        <div className="w-screen z-40 py-1 top-[4rem] rounded-sm bg-white">
          <div className="flex justify-between items-center p-1 pl-0 pr-4">
            <div className="mb-2">
              <h2 className="ml-4 opacity-70">
                {(metadata as any)?.title || "Call Chain Viewer"}
              </h2>
              <p className="ml-4 opacity-70 mt-1 text-xs">
                Upload and Explore CloudFrame Artifacts and Their Dynamic
                Relationships
              </p>
            </div>
            {folderOptions.length > 0 && (<CloudFrameDropdown
              options={folderOptions}
              onChange={(event: any, option: any) =>
                setFolderOption(option?.text as string)
              }
              styles={{ dropdown: { width: "200px" } }}
              defaultSelectedKey={
                folderOptions.length > 0 ? folderOptions[0].key : undefined
              }
            />)}
            <div className="flex justify-end items-center gap-[.1rem]">
              <Switcher
                checked={!!graphViz}
                onChange={(val: boolean) => {
                  setGraphViz(val);
                  if (val === false) {
                    setTimeout(() => {
                      graphRef.current?.restartSimulation();
                    }, 200);
                  }
                }}
              />

              <button
                className="custom-btn"
                disabled={false}
                onClick={() => {
                  ref.current?.setShowResetConfirmation(true);
                }}
                style={{
                  opacity: false ? 0.3 : 1,
                }}
              >
                <Icon
                  iconName="Clear"
                  className="font-semibold"
                  style={{ fontSize: ".7rem" }}
                />
                Reset Canvas
              </button>

              <button
                className="custom-btn"
                disabled={!selectedProgramId}
                onClick={() => {
                  downloadSVG();
                }}
                style={{
                  opacity: selectedProgramId ? 1 : 0.5,
                  background: selectedProgramId ? "black" : "grey",
                }}
              >
                <Icon
                  iconName="Download"
                  className="font-semibold"
                  style={{ fontSize: ".7rem" }}
                />
                Download SVG
              </button>
              <button
                className="custom-btn"
                disabled={false}
                onClick={() =>
                  document?.getElementById("upload-zip-input")?.click()
                }
                style={{
                  opacity: false ? 0.3 : 1,
                }}
              >
                <input
                  type="file"
                  id="upload-zip-input"
                  onChange={handleClickUploadZip}
                  style={{ display: "none" }}
                />
                <Icon
                  iconName="BulkUpload"
                  className="font-semibold"
                  style={{ fontSize: ".7rem" }}
                />
                Upload ZIP
              </button>
            </div>
          </div>
        </div>

        <div style={{ height: "100vh", display: "flex" }}>
          <SideBar
            selectedProgramId={
              selectedProgramId || artifactIds[0] || programIds[0] || ""
            }
            metadata={metadata}
            programIds={programIds}
            artifactIds={artifactIds}
            onSelectProgramId={handleProgramChange}
          />
          <div className="bg-white" style={{ height: "100vh", width: "100vw" }}>
            <div>
              {graphViz ? (
                <GraphGraph
                  ref={graphRef}
                  metadata={metadata}
                  selectedProgramId={selectedProgramId}
                  // graphData={graphData}
                  graphData={
                    currentIndex === -1
                      ? { nodes: [], links: [] }
                      : isProgramSelected
                        ? programs[currentIndex]
                        : convertToExecutionFlowGraph(artifacts[currentIndex])
                  }
                  // graphData={convertToExecutionFlowGraph(newGraph as any)}
                  // graphData={convertToExecutionFlowGraph(newCustomGraph as any)}
                  onClickNode={onClickNode}
                  onClickLink={onClickLink}
                />
              ) : (
                <DepGraph
                  ref={graphRef}
                  selectedProgramId={selectedProgramId}
                  metadata={metadata}
                  //graphData={graphData}
                  graphData={
                    currentIndex === -1
                      ? { nodes: [], links: [] }
                      : isProgramSelected
                        ? programs[currentIndex]
                        : convertToExecutionFlowGraph(artifacts[currentIndex])
                  }
                  // graphData={convertToExecutionFlowGraph(newGraph as any)}
                  // graphData={convertToExecutionFlowGraph(newCustomGraph as any)}
                  onClickNode={onClickNode}
                  onClickLink={onClickLink}
                />
              )}
            </div>
          </div>
        </div>

        <div className="fixed left-[20.5rem] bottom-10 hidden">
          <ChartControls
            handleFitIntoView={() => {
              graphRef.current?.restartSimulation();
            }}
            handleZoomIn={() => null}
            handleZoomOut={() => null}
          />
        </div>

        <DialogsComponent
          ref={ref}
          handleClear={handleClear}
          setSelectedProgramId={setSelectedProgramId}
        />
      </div>

      {/* <div style={{ position: "absolute", left: 10, top: 100 }}>
        <JobCard />
      </div> */}
    </div>
  );
};
