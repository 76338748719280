import JSZip, { folder } from "jszip";
import { useEffect, useState } from "react";
import { filterUnconnectedNodes } from "../components/dependecy-graph/graph.service";
interface NewData {
  [foldername: string]: {
    jcl?: Promise<string>[];
    program?: Promise<string>[];
    metadata?: Promise<string>[];
    // Add other optional keys as needed
  };
}
const useArtifactUpload = () => {
  const [artifacts, setArtifacts] = useState<any[]>([]);
  const [artifactIds, setArtifactIds] = useState<string[]>([]);
  const [metadata, setMetadata] = useState(null);
  const [confData, setConfData] = useState({});
  const [programs, setPrograms] = useState<any>([]);
  const [programIds, setProgramIds] = useState<string[]>([]);

  const [data, setData] = useState({});

  useEffect(() => {
    const storedArtifacts = sessionStorage.getItem("storedArtifacts");
    const storedArtifactIds = sessionStorage.getItem("storedArtifactIds");
    const storedPrograms = sessionStorage.getItem("storedPrograms");
    const storedProgramIds = sessionStorage.getItem("storedProgramIds");
    const storedArtifactMetadata = sessionStorage.getItem("artifactsMetadata");
    const folderNames = sessionStorage.getItem("storedFolderNames");
    const storedConfs = sessionStorage.getItem("artifactConf");
    if (storedArtifacts) {
      setArtifacts(JSON.parse(storedArtifacts));
    }

    if (storedArtifactIds) {
      setArtifactIds(JSON.parse(storedArtifactIds));
    }
    if (storedPrograms) {
      setPrograms(JSON.parse(storedPrograms));
    }
    if (storedProgramIds) {
      setProgramIds(JSON.parse(storedProgramIds));
    }
    if (storedArtifactMetadata) {
      setMetadata(JSON.parse(storedArtifactMetadata));
    }
    if (storedConfs) {
      setConfData(JSON.parse(storedConfs));
    }
  }, []);

  function convertProgramToNodesAndLinks(data: any) {
    const nodes: any[] = [];
    const links: any[] = [];
    let ids: string[] = [];

    data.forEach((item: any) => {
      item = JSON.parse(item);
      const node = { id: item.id, ...item };
      nodes.push(node);
      if (item.isRootNode) {
        ids.push(item.id);
      }

      if (item.links) {
        item.links?.forEach((link: { id: string }) => {
          links.push({ source: item.id, target: link.id });
        });
      }
    });

    links.forEach((link) => {
      const { source, target } = link;
      if (!nodes.some((n) => n.id === source)) {
        nodes.push({ id: source, ProgramName: source });
      }
      if (!nodes.some((n) => n.id === target)) {
        nodes.push({ id: target, ProgramName: target });
      }
    });

    const result = ids?.map((x) => {
      return filterUnconnectedNodes(
        nodes.map((y) => (x === y.id ? { ...y, isDriverProgram: true } : y)),
        links,
        x
      );
    });

    setPrograms(result || []);
    setProgramIds(ids || []);
    sessionStorage.setItem("storedPrograms", JSON.stringify(result || []));
    sessionStorage.setItem("storedProgramIds", JSON.stringify(ids || []));
  }
  // console.log(data)
  const handleUploadZip = async (zipFile: File) => {
    if (
      zipFile.type === "application/zip" ||
      zipFile.type === "application/x-zip-compressed"
    ) {
      const zip = new JSZip();

      try {
        const zipContents = await zip.loadAsync(zipFile);
        const jclJsonFiles: Promise<string>[] = [];
        const programJsonFiles: Promise<string>[] = [];
        const metaDataFiles: Promise<string>[] = [];
        const extractedIds: string[] = [];
        const folderNames: string[] = [];
        let schemaMetadata = null;
        const newData: NewData = {};
        zipContents.forEach((_relativePath, file) => {
          if (file.name?.endsWith(".json") || file.name?.endsWith(".JSON")) {
            let fileId = file.name.split(".")[0];
            fileId = fileId.replace(/\\/g, "/");
            const [foldername, firstlayer, secondLayer] = fileId.split("/");
            if (artifactIds.includes(fileId)) {
              throw new Error(`File "${fileId}.json" already exists.`);
            }
            if (fileId.includes("metadata")) {
              schemaMetadata = file.async("text");
              const schemaMetadataTest = file.async("text");
              metaDataFiles.push(schemaMetadataTest);
              if (!newData[foldername]) {
                newData[foldername] = {};
              }
              if (!newData[foldername]["metadata"]) {
                newData[foldername]["metadata"] = [];
              }
              (newData[foldername]["metadata"] as any).push(schemaMetadataTest);
            } else {
              if (firstlayer === "jcl") {
                extractedIds.push(secondLayer?.toUpperCase());
                const jclPromise = file.async("text");
                jclJsonFiles.push(jclPromise);
                if (!newData[foldername]) {
                  newData[foldername] = {};
                }
                if (!newData[foldername]["jcl"]) {
                  newData[foldername]["jcl"] = [];
                }
                (newData[foldername]["jcl"] as any).push(jclPromise);
              } else if (firstlayer === "object" || firstlayer === "program") {
                const programPromise = file.async("text");
                programJsonFiles.push(programPromise);
                if (!newData[foldername]) {
                  newData[foldername] = {};
                }
                if (!newData[foldername]["program"]) {
                  newData[foldername]["program"] = [];
                }
                (newData[foldername]["program"] as any).push(programPromise);
              }
            }
            setData(newData)
          }
        });

        if (!schemaMetadata) {
          throw new Error(`metadata file doesn't exist !!!`);
        }
        const parsedMetadata = JSON.parse((await schemaMetadata) || "{}");
        setMetadata(parsedMetadata);
        const jclJsonContents = await Promise.all(jclJsonFiles);
        const programJsonContent = await Promise.all(programJsonFiles);
        const parsedPrograms: any[] = [];
        const parsedProgramsTest: any = {};
        let folderName: any;
        let parsedMData: any;
        let graphTypeMData: any;
        await Promise.all(
          Object.entries(newData).map(async ([foldername, { jcl, program, metadata }]) => {
            const parsedMetadata = await Promise.all((metadata as any)) || "{}"
            if (jcl) {
              const resolvedJcl = await Promise.all((jcl as any));
              const resolvedProgram = await Promise.all((program as any));
              parsedMetadata.map((metadata) => {
                parsedMData = JSON.parse(metadata)
                graphTypeMData = parsedMData.graphType;
                folderNames.push(graphTypeMData)
              });
              parsedProgramsTest[graphTypeMData] = {
                jcl: [],
                program: [],
                metadata: parsedMData,
                artifacts: [],
              };
              parsedProgramsTest[graphTypeMData].jcl = jclJsonContents.map(content => JSON.parse(content));
              parsedProgramsTest[graphTypeMData].program = resolvedProgram.map(programContent => JSON.parse(programContent));
              parsedProgramsTest[graphTypeMData].metadata = parsedMData;
              resolvedJcl?.forEach((content) => {
                const parsedJson = JSON.parse(content);
                parsedProgramsTest[graphTypeMData].artifacts.push([
                  parsedJson,
                  {
                    program: resolvedProgram.map((programContent) => JSON.parse(programContent)),
                  }
                ]);
              });
            } else {
              const resolvedProgram = await Promise.all((program as any));
              parsedMetadata.map((metadata) => {
                parsedMData = JSON.parse(metadata)
                graphTypeMData = parsedMData.graphType;
                folderNames.push(graphTypeMData)
              });
              parsedProgramsTest[graphTypeMData] = {
                program: resolvedProgram.map((programContent) =>
                  JSON.parse(programContent)
                ),
                metadata: parsedMData,
              };
            }
            folderName = foldername;
          })
        );
        jclJsonContents.forEach((content) => {
          const parsedJson = JSON.parse(content);
          parsedPrograms.push([
            parsedJson,
            {
              programs: programJsonContent.map((programContent) =>
                JSON.parse(programContent)
              ),
            },
          ]);
        });
        // convertProgramToNodesAndLinks(programJsonContent)
        setArtifacts([...artifacts, ...parsedPrograms]);
        setArtifactIds([...artifactIds, ...extractedIds]);
        setConfData(parsedProgramsTest)
        sessionStorage.setItem(
          "storedArtifacts",
          JSON.stringify([...artifacts, ...parsedPrograms])
        );
        sessionStorage.setItem(
          "storedArtifactIds",
          JSON.stringify([...artifactIds, ...extractedIds])
        );
        sessionStorage.setItem(
          "artifactsMetadata",
          JSON.stringify(parsedMetadata)
        );
        sessionStorage.setItem("artifactConf", JSON.stringify(parsedProgramsTest));
        sessionStorage.setItem("storedFolderName", JSON.stringify(folderNames));
      } catch (error) {
        console.error("Error processing zip:", error);
        throw error;
      }
    } else {
      throw new Error("Please upload a zip file.");
    }
  };

  const handleUploadJson = (file: File) => {
    const fileId = file.name.split(".")[0];
    if (artifactIds.includes(fileId)) {
      throw new Error(`File "${fileId}.json" already exists.`);
    }

    if (file.type === "application/json") {
      const reader = new FileReader();
      reader.onload = async (e) => {
        try {
          const parsedJson = JSON.parse(e.target?.result as string);
          setArtifacts([...artifacts, parsedJson]);
          setArtifactIds([...artifactIds, fileId]);
          sessionStorage.setItem(
            "storedArtifacts",
            JSON.stringify([...artifacts, parsedJson])
          );
          sessionStorage.setItem(
            "storedArtifactIds",
            JSON.stringify([...artifactIds, fileId])
          );
        } catch (error) {
          console.error("Error parsing JSON file:", error);
          throw error;
        }
      };
      reader.readAsText(file);
    } else {
      throw new Error("Please upload a json file.");
    }
  };

  const handleConfClear = () => {
    setConfData({});
  };

  return {
    artifacts,
    artifactIds,
    programs,
    programIds,
    metadata,
    confData,
    handleUploadZip,
    handleUploadJson,
    handleConfClear,
  };
};

export { useArtifactUpload };
