import * as React from "react";
import { SVGProps } from "react";
import { appColors } from "../../styles/colors";
const SvgComments = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={35}
    height={35}
    fill="none"
    viewBox="0 -0.5 25 25"
    {...props}
  >
    <path
      stroke={appColors.blueBase}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.5}
      d="M18.1 5H6.9a1.379 1.379 0 0 0-1.4 1.358V14.5a1.38 1.38 0 0 0 1.4 1.358h3.87c.218-.001.434.048.63.142l5.651 3L17 14.5h1.43c.58.01 1.06-.453 1.07-1.034V6.358A1.379 1.379 0 0 0 18.1 5Z"
      clipRule="evenodd"
    />
    <path
      fill={appColors.blueBase}
      d="M8.5 8.25a.75.75 0 0 0 0 1.5v-1.5Zm8 1.5a.75.75 0 0 0 0-1.5v1.5Zm-8 1.5a.75.75 0 0 0 0 1.5v-1.5Zm6 1.5a.75.75 0 1 0 0-1.5v1.5Zm-6-3h8v-1.5h-8v1.5Zm0 3h6v-1.5h-6v1.5Z"
    />
  </svg>
);
export default SvgComments;
