import React from "react";
import { ICard } from "./card.types";
import { appColors } from "../../styles/colors";
import { getCardColors } from "./card.service";
import JobCard from "./job-card/job-card";
import StepCard from "./step-card/step-card";
import { Icon, Panel } from "@fluentui/react";
import { useBoolean } from "@fluentui/react-hooks";
import SvgLineOfControl from "../../assets/svg/line-of-control.icon";
import SvgComments from "../../assets/svg/comments.icon";
import SvgSql from "../../assets/svg/sql.icon";
import SvgCisc from "../../assets/svg/cisc.icon";
import SvgCopybook from "../../assets/svg/copybook.icon";
import SvgComplexity from "../../assets/svg/complexity.icon";
import SvgCopybook2 from "../../assets/svg/copybook2.icon";

const customUIFields = [
  "stats",
  "migrated",
  "ProgramName",
  "isRootNode",
  "isDriverProgram",
  "type",
  "nodeType",
  "isSupported",
  "migrated",
  "COMPLEX_PARA",
  "links",
  "FUNCTIONS",
  "FILES",
  "SQL",
  "TABLES",
  "VERB_COUNT",
  "className",
  "cursor",
  "cx",
  "cy",
  "dx",
  "dy",
  "fill",
  "legend",
  "fontColor",
  "fontSize",
  "fontWeight",
  "fx",
  "fy",
  "highlighted",
  "id",
  "index",
  "label",
  "labelPosition",
  "opacity",
  "overrideGlobalViewGenerator",
  "renderLabel",
  "size",
  "stroke",
  "strokeWidth",
  "svg",
  "type",
  "vx",
  "vy",
  "x",
  "y",
];

const Card: React.FC<ICard> = (props) => {
  const {
    name,
    id,
    dynamicCall,
    selectedProgramId,
    recursive,
    isMissingProgram,
    isDriverProgram,
    metadata,
    rest,
  } = props;

  // console.log(props);
  const [isOpen, { setTrue: openPanel, setFalse: dismissPanel }] =
    useBoolean(false);

  const getCardBodyTopMargin = () => {
    let marginTop = 26;
    if (recursive) {
      marginTop = 28;
    }
    if (id.length > 20) {
      marginTop = 38;
    }

    return -marginTop;
  };

  const { backgroundColor, textColor } =
    !!metadata?.legend && Object.keys(metadata?.legend || "{}").length > 0
      ? {
          backgroundColor:
            metadata?.legend?.[rest.legend]?.nodeColor || appColors.grey20,
          textColor: metadata?.legend?.[rest.legend]?.textColor || "black",
        }
      : getCardColors(
          name,
          selectedProgramId as string,
          recursive,
          dynamicCall,
          isMissingProgram,
          isDriverProgram,
          rest?.isSupported
        );

  if (rest?.nodeType === "job") {
    return <JobCard name={name} />;
  }
  if (rest?.nodeType === "step") {
    return <StepCard name={name} node={rest} />;
  }

  const getLabel = (key: string) => {
    for (let i = 0; i < metadata.entityLabels?.length; i++) {
      const entity = metadata?.labels?.[metadata.entityLabels?.[i]]?.[key];
      if (entity) {
        return entity;
      }
    }

    return key;
  };

  return (
    <>
      <div key={id} className="card">
        <div
          className="card-body shadow-2xl relative "
          style={{
            background: backgroundColor,
            color: textColor,
          }}
        >
          <div style={{ marginTop: getCardBodyTopMargin() }}>
            <h2
              className="m-0 p-0 px-4 max-w-[90%] text-4xl"
              style={{ lineHeight: "2rem", fontSize: "2.3rem" }}
            >
              {name}
            </h2>
            {/* {dynamicCall && <p className="mt-1">Dynamic call</p>} */}
            {/* {selectedProgramId === name && <p className="mt-1">Driver program</p>} */}
            {recursive && <p>Recursive program</p>}
          </div>
          <div className="text-center mt-3 pb-3">
            <Icon
              onClick={(e) => {
                e.stopPropagation();

                openPanel();
              }}
              iconName="Info"
              style={{
                fontSize: 42,
                color: "white",
                background: "black",
                borderRadius: "50%",
              }}
            />
          </div>
        </div>
      </div>
      <Panel
        isLightDismiss
        isOpen={isOpen}
        onDismiss={dismissPanel}
        closeButtonAriaLabel="Close"
        headerText={name + (rest.migrated ? " (Migrated)" : "")}
      >
        <div className="mt-4">
          {!!rest.stats && (
            <>
              <h3>Statistics</h3>
              <hr className="my-2" />
              <div className="flex flex-col gap-2 flex-wrap">
                {rest?.stats?.["Total number of lines"] !== undefined && (
                  <div
                    className="p-2 text-center shadow-md rounded-sm flex flex-row gap-4 items-center"
                    style={{
                      background: appColors.whiteLight,
                      flex: "1 30%",
                    }}
                  >
                    <div>
                      <SvgLineOfControl />
                    </div>
                    <div className=" mx-auto">
                      <p className="text-xl font-semibold">
                        {rest?.stats?.["Total number of lines"]}
                      </p>
                      <p className="text-md">Total number of lines</p>
                    </div>
                  </div>
                )}
                {rest?.stats?.["Total commented lines"] !== undefined && (
                  <div
                    className="p-2 text-center shadow-md rounded-sm flex flex-row gap-4 items-center"
                    style={{ background: appColors.whiteLight, flex: "1 30%" }}
                  >
                    <div>
                      <SvgComments />
                    </div>
                    <div className="mx-auto">
                      <p className="text-xl font-semibold">
                        {rest?.stats?.["Total commented lines"]}
                      </p>
                      <p className="text-md">Total commented lines</p>
                    </div>
                  </div>
                )}
                {rest?.stats?.["Total SQL Statements"] !== undefined && (
                  <div
                    className="p-2 text-center shadow-md rounded-sm flex flex-row gap-4 items-center"
                    style={{
                      background: appColors.whiteLight,
                      flex: "1 30%",
                    }}
                  >
                    <div>
                      <SvgSql />
                    </div>
                    <div className="mx-auto">
                      <p className="text-xl font-semibold">
                        {rest?.stats?.["Total SQL Statements"]}
                      </p>
                      <p className="text-md">Total SQL Statements</p>
                    </div>
                  </div>
                )}
                {rest?.stats?.["Total CICS Statements"] !== undefined && (
                  <div
                    className="p-2 text-center shadow-md rounded-sm flex flex-row gap-4 items-center"
                    style={{
                      background: appColors.whiteLight,
                      flex: "1 30%",
                    }}
                  >
                    <div>
                      <SvgCisc />
                    </div>
                    <div className="mx-auto">
                      <p className="text-xl font-semibold">
                        {rest?.stats?.["Total CICS Statements"]}
                      </p>
                      <p className="text-md">Total CICS Statements</p>
                    </div>
                  </div>
                )}
                {rest?.stats?.["Total number of lines in Copybook"] !==
                  undefined && (
                  <div
                    className="p-2 text-center shadow-md rounded-sm flex flex-row gap-4 items-center"
                    style={{
                      background: appColors.whiteLight,
                      flex: "1 30%",
                    }}
                  >
                    <div>
                      <SvgCopybook />
                    </div>
                    <div className="mx-auto">
                      <p className="text-xl font-semibold">
                        {rest?.stats?.["Total number of lines in Copybook"]}
                      </p>
                      <p className="text-md">
                        Total number of lines in Copybook
                      </p>
                    </div>
                  </div>
                )}
                {rest?.stats?.[
                  "Total number of commented lines in Copybook"
                ] !== undefined && (
                  <div
                    className="p-2 text-center shadow-md rounded-sm flex flex-row gap-4 items-center"
                    style={{
                      background: appColors.whiteLight,
                      flex: "1 30%",
                    }}
                  >
                    <div>
                      <SvgCopybook2 />
                    </div>
                    <div className="mx-auto">
                      <p className="text-xl font-semibold">
                        {
                          rest?.stats?.[
                            "Total number of commented lines in Copybook"
                          ]
                        }
                      </p>
                      <p className="text-md">
                        Total number of commented lines in Copybook
                      </p>
                    </div>
                  </div>
                )}
                {rest?.stats?.["CyclomaticComplexity"] !== undefined && (
                  <div
                    className="p-2 text-center shadow-md rounded-sm flex flex-row gap-4 items-center"
                    style={{
                      background: appColors.whiteLight,
                      flex: "1 30%",
                    }}
                  >
                    <div>
                      <SvgComplexity />
                    </div>
                    <div className="mx-auto">
                      <p className="text-xl font-semibold">
                        {rest?.stats?.["CyclomaticComplexity"]}
                      </p>
                      <p className="text-md">Cyclomatic Complexity</p>
                    </div>
                  </div>
                )}
              </div>
            </>
          )}

          {Object.entries(rest)
            ?.filter((x) => !customUIFields.includes(x[0]))
            ?.map(([key, value]) => {
              if (
                typeof value === "string" ||
                typeof value === "number" ||
                typeof value === "boolean" ||
                value === undefined ||
                value === null
              )
                return (
                  <div className="mt-6">
                    <h3>{getLabel(key)}</h3>
                    <hr className="my-2" />
                    <div
                      className="flex flex-row justify-between p-4 shadow-md rounded-sm"
                      style={{ background: appColors.whiteLight }}
                    >
                      <div className=" font-semibold">{value + ""}</div>
                    </div>
                  </div>
                );
              return null;
            })}

          {rest?.FUNCTIONS?.length > 0 && (
            <div className="mt-6">
              <h3>Function calls</h3>
              <hr className="my-2" />
              <div
                className="flex flex-row justify-between p-4 shadow-md rounded-sm"
                style={{ background: appColors.whiteLight }}
              >
                <ul className="ml-4 font-semibold">
                  {rest?.FUNCTIONS?.map((call: string) => (
                    <li key={call}>{call}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {rest?.FILES?.length > 0 && (
            <div className="mt-6">
              <h3>Files</h3>
              <hr className="my-2" />
              <div className="flex flex-col gap-3">
                {rest?.FILES?.map((file: Record<string, string>) => (
                  <div key={file.name}>
                    <div
                      className="pl-4 font-bold flex items-center"
                      style={{
                        background: appColors.blueBase,
                        height: "1.5rem",
                        color: appColors.white,
                      }}
                    >
                      File
                    </div>
                    <div
                      className="flex flex-row justify-between p-4 shadow-md rounded-sm"
                      style={{ background: appColors.whiteLight }}
                    >
                      <div className="font-semibold flex flex-col gap-[4px]">
                        <p>IO:</p>
                        <p>Name:</p>
                        <p>DD Name:</p>
                        <p>Record Format:</p>
                        <p>Record Max Length:</p>
                      </div>
                      <div className="flex flex-col gap-[4px]">
                        <p>{file.io}</p>
                        <p>{file.name}</p>
                        <p>{file.dd}</p>
                        <p>{file.type}</p>
                        <p>{file.maxLen}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {rest?.SQL?.length > 0 && (
            <div className="mt-6">
              <h3>SQL Count</h3>
              <hr className="my-2" />
              <div className="flex gap-2 items-center flex-wrap">
                {rest?.SQL?.map((sql: Record<string, string>) => (
                  <div
                    key={sql.sql}
                    className="p-4 shadow-md rounded-sm text-center"
                    style={{
                      flex: "1 0 15%",
                      maxWidth: "20%",
                      background: appColors.whiteLight,
                    }}
                  >
                    <p className="font-semibold text-center mb-1">{sql.sql}</p>
                    <p>{sql.count}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {rest?.CICS?.length > 0 && (
            <div className="mt-6">
              <h3>CICS Count</h3>
              <hr className="my-2" />
              <div className="flex gap-2 items-center flex-wrap">
                {rest?.CICS?.map((cisc: Record<string, string>) => (
                  <div
                    key={cisc.CICS}
                    className="p-4 shadow-md rounded-sm text-center"
                    style={{
                      flex: "1 0 15%",
                      maxWidth: "20%",
                      background: appColors.whiteLight,
                    }}
                  >
                    <p className="font-semibold text-center mb-1">
                      {cisc.CICS}
                    </p>
                    <p>{cisc.count}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
          {rest?.COMPLEX_PARA?.length > 0 && (
            <div className="mt-6">
              <h3>Complex Parameters</h3>
              <hr className="my-2" />
              <div
                className="flex flex-row justify-between p-4 shadow-md rounded-sm"
                style={{ background: appColors.whiteLight }}
              >
                <ul className="ml-4 font-semibold">
                  {rest?.COMPLEX_PARA?.map((para: string) => (
                    <li key={para}>{para}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          {rest?.TABLES?.length > 0 && (
            <div className="mt-6">
              <h3>Table's used</h3>
              <hr className="my-2" />
              <div className="flex flex-wrap gap-2 justify-between">
                {rest?.TABLES?.map((table: any) => (
                  <div
                    key={table.table}
                    className="flex flex-col shadow-md rounded-sm w-[48%] mt-2"
                    style={{ background: appColors.whiteLight }}
                  >
                    <div
                      className="font-bold flex flex-col justify-center px-4 text-white"
                      style={{ background: appColors.blueBase, height: "2rem" }}
                    >
                      {table?.table}
                    </div>
                    <div className="p-4">
                      <p className="font-semibold">CRUD:</p>
                      <hr className="my-2" />
                      <ul className="ml-4">
                        <li>SELECT: {table.select}</li>
                        <li>INSERT: {table.insert}</li>
                        <li>DELETE: {table.delete}</li>
                        <li>UPDATE: {table.update}</li>
                        {/* {table?.CRUD?.map((crud: string) => (
                          <li>{crud}</li>
                        ))} */}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
          {rest?.VERB_COUNT?.length > 0 && (
            <div className="mt-6">
              <h3>Verb's Count</h3>
              <hr className="my-2" />
              <div className="flex gap-2 items-center flex-wrap">
                {rest?.VERB_COUNT?.map((verb: Record<string, string>) => (
                  <div
                    key={verb.verb}
                    className="p-4 shadow-md rounded-sm text-center flex flex-col justify-center items-center"
                    style={{
                      flex: "1 0 15%",
                      maxWidth: "20%",
                      background: appColors.whiteLight,
                    }}
                  >
                    <p className="font-semibold text-center mb-1">
                      {verb.verb}
                    </p>
                    <p>{verb.count}</p>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </Panel>
    </>
  );
};

export { Card };
