import * as React from "react";
import { appColors } from "../../styles/colors";
const SvgCopybook = (props: any) => (
  <svg
    width={30}
    height={30}
    viewBox="0 0 512 512"
    data-name="Camada 1"
    id="Camada_1"
    {...props}
  >
    <defs>
      <style>
        {`.cls-1{fill:#333;}.cls-2{fill:#666;}.cls-3{fill:${appColors.blueBase};}.cls-4{fill:#e6e6e6;opacity:0.8;}.cls-4,.cls-5{stroke:#333;stroke-miterlimit:10;stroke-width:2px;}.cls-5{fill:none;}.cls-6{fill:#4d4d4d;}`}
      </style>
    </defs>
    <title />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={44.24}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={23.03}
      x={73.51}
      y={69.33}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={94.42}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={119.51}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={144.59}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={194.77}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={169.68}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={470.72}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={219.85}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={244.94}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={445.63}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={270.03}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={295.11}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={320.2}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={345.29}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={370.37}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={395.46}
    />
    <rect
      className="cls-1"
      height={3.65}
      rx={1.75}
      ry={1.75}
      width={22.81}
      x={73.74}
      y={420.55}
    />
    <rect
      className="cls-2"
      height={454.75}
      rx={12}
      ry={12}
      width={351.75}
      x={86.74}
      y={26}
    />
    <rect
      className="cls-3"
      height={454.75}
      rx={12}
      ry={12}
      width={344.43}
      x={86.94}
      y={31.25}
    />
    <rect
      className="cls-4"
      height={73.44}
      rx={12}
      ry={12}
      width={124.98}
      x={277.86}
      y={376.3}
    />
    <line className="cls-5" x1={289.49} x2={391.2} y1={395} y2={394.09} />
    <line className="cls-5" x1={289.49} x2={391.2} y1={413.25} y2={412.34} />
    <line className="cls-5" x1={289.49} x2={391.2} y1={431.49} y2={430.58} />
    <circle className="cls-1" cx={97} cy={45.39} r={3.42} />
    <circle className="cls-1" cx={97} cy={70.47} r={3.42} />
    <circle className="cls-1" cx={97} cy={95.56} r={3.42} />
    <circle className="cls-1" cx={97} cy={120.65} r={3.42} />
    <circle className="cls-1" cx={97} cy={145.73} r={3.42} />
    <circle className="cls-1" cx={97} cy={170.82} r={3.42} />
    <circle className="cls-1" cx={97} cy={195.91} r={3.42} />
    <circle className="cls-1" cx={97} cy={220.99} r={3.42} />
    <circle className="cls-1" cx={97} cy={246.08} r={3.42} />
    <circle className="cls-1" cx={97} cy={271.17} r={3.42} />
    <circle className="cls-1" cx={97} cy={296.25} r={3.42} />
    <circle className="cls-1" cx={97} cy={321.34} r={3.42} />
    <circle className="cls-1" cx={97} cy={346.43} r={3.42} />
    <circle className="cls-1" cx={97} cy={371.51} r={3.42} />
    <circle className="cls-1" cx={97} cy={396.6} r={3.42} />
    <circle className="cls-1" cx={97} cy={421.69} r={3.42} />
    <circle className="cls-1" cx={97} cy={446.77} r={3.42} />
    <circle className="cls-1" cx={97} cy={471.86} r={3.42} />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={44.24}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={23.03}
      x={73.51}
      y={69.33}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={94.42}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={119.51}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={144.59}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={194.77}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={169.68}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={470.72}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={219.85}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={244.94}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={445.63}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={270.03}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={295.11}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={320.2}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={345.29}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={370.37}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={395.46}
    />
    <rect
      className="cls-6"
      height={2.28}
      rx={1.09}
      ry={1.09}
      width={22.81}
      x={73.74}
      y={420.55}
    />
  </svg>
);
export default SvgCopybook;
