export class SharedService {
  static prevTr = null;
  static getPrevTr() {
    return this.prevTr;
  }

  static setPrevTr(tr: any) {
    this.prevTr = tr;
  }
}
