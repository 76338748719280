import { CloudFrameText } from "@cloudframe/text";
import { Icon } from "@fluentui/react";
import { appColors } from "../../../styles/colors";
import { ISearchItemProps } from "./search-item.types";

const SearchItem = (props: ISearchItemProps) => {
  const { id, name, onClick, selected = false } = props;

  return (
    <div key={id}>
      <div
        onClick={onClick}
        style={{
          border: selected
            ? `2px solid ${appColors.greenBase}`
            : `1px solid ${appColors.greyCardOutline}`,
          background: appColors.white,
        }}
        className={`shadow-md p-6 rounded-sm cursor-pointer hover:bg-white`}
      >
        <div className="flex items-center justify-between">
          <CloudFrameText
            textInfo={name}
            variant="medium"
            style={{
              wordBreak: "break-word",
            }}
            className="opacity-70 -mt-[.2rem] font-semibold"
          />
          <Icon
            iconName="TextDocument"
            style={{
              color: "rgb(64, 103, 249)",
              paddingLeft: ".5rem",
              transform: "scale(1.2)",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
