import { SearchBox } from "@fluentui/react";
import React, { useEffect, useMemo, useState } from "react";
import SearchItem from "./search-item";
import { ISidebar } from "./sidebar.types";
import SvgLeftArrow from "../../assets/left-arrow.svg";
import { SvgPlus } from "../../assets/svg/plus.icon";
import { SvgMinus } from "../../assets/svg/minus.icon";
import { SvgNotFound } from "../../assets/svg/not-found.icon";
import { style } from "d3";

const SideBar: React.FC<ISidebar> = (props) => {
  const {
    metadata,
    programIds,
    artifactIds,
    selectedProgramId,
    onSelectProgramId,
  } = props;

  const [programs, setPrograms] = useState<string[]>([]);
  const [artifacts, setArtifacts] = useState<string[]>([]);
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(true);
  const [openSection, setOpenSection] = useState("section-1");

  const progContainer =
    artifactIds?.length === 0 && programIds?.length > 0 ? "26" : "30.5";

  const sortedArtifacts = useMemo(() => {
    const sorted = [...artifacts];
    sorted.sort();
    return sorted;
  }, [artifacts]);

  const sortedPrograms = useMemo(() => {
    const sorted = [...programs];
    sorted.sort();
    return sorted;
  }, [programs]);

  useEffect(() => {
    if (programIds.length === 0 && artifactIds.length === 0) {
      setOpenSection("");
    } else if (artifactIds.length === 0 && programIds.length > 0) {
      setOpenSection("section-2");
    } else {
      setOpenSection("section-1");
    }
  }, [artifactIds.length, programIds.length]);

  useEffect(() => {
    setPrograms(
      programIds.filter((programId) => {
        return programId.toLowerCase().includes(searchText.toLowerCase());
      })
    );
    setArtifacts(
      artifactIds.filter((artId) => {
        return artId.toLowerCase().includes(searchText.toLowerCase());
      })
    );
  }, [programIds, artifactIds, searchText]);

  if (!open) {
    return (
      <div
        className="shadow-sm relative"
        style={{
          width: open ? "20rem" : "4rem",
          marginTop: "0rem",
          position: "fixed",
          background: "#F9F9FA",
          height: "100vh",
        }}
      >
        <div className="rounded-sm pb-4 mt-8 h-100%">
          <div
            style={{ border: "1px solid #f6f7f8" }}
            className="p-4 pr-4 flex flex-row justify-end"
          >
            <div
              className="-mr-[2rem] mt-[4.5rem] cursor-pointer"
              onClick={() => setOpen(!open)}
            >
              <img
                src={SvgLeftArrow}
                alt="Left arrow"
                style={{ transform: "rotate(180deg)" }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="shadow-sm relative"
      style={{
        width: open ? "20rem" : "4rem",
        marginTop: "0rem",
        position: "fixed",
        background: "#F9F9FA",
        minHeight: "100vh",
      }}
    >
      <div className="rounded-sm pb-2 mt-4 h-100%">
        <div style={{ border: "1px solid #f6f7f8" }} className="p-4 -mb-1">
          <div>
            <SearchBox
              placeholder="Search"
              value={searchText}
              onChange={(e: any) => setSearchText(e?.target.value || "")}
              styles={{
                icon: {
                  transform: "scale(1.1)",
                  fontWeight: "600",
                },
                root: {
                  height: "2.8rem",
                  width: "100%",
                  border: "1px solid rgba(0,0,0,.2)",
                },
                iconContainer: { marginRight: ".2rem" },
              }}
            />
          </div>
          <div className="-mb-2">
            <div className="flex justify-between items-center mt-4 ml-1 border-b-2 overflow-visible">
              <div
                className="flex flex-1 justify-between items-center cursor-pointer"
                // onClick={() => {
                //   setOpenSection("section-1");
                // }}
              >
                <div className=" opacity-80 text-sm">Entities</div>
                {/* <div className="ml-auto mr-2">
                  {openSection1 ? <SvgMinus /> : <SvgPlus />}
                </div> */}
              </div>
              <div
                className="-mr-8 cursor-pointer"
                onClick={() => setOpen(!open)}
              >
                <img src={SvgLeftArrow} />
              </div>
            </div>
            <hr className="mt-1 ml-1 mr-1 border-gray-100 opacity-50" />
          </div>
        </div>
        <div
          className="overflow-auto"
          style={{ height: "calc(100vh - 21rem)" }}
        >
          {(artifactIds.length > 0 ||
            (artifactIds.length === 0 && programIds.length === 0)) && (
            <details
              id="section-1"
              key={"section-1"}
              open={openSection === "section-1"}
            >
              <summary
              // onClick={(e) => {
              //   e.preventDefault();
              //   setOpenSection(
              //     openSection === "section-1" ? "section-2" : "section-1"
              //   );
              // }}
              >
                <span>
                  {`${metadata?.entityLabels?.[0] || "Jobs"} `}
                  <span
                    className={`text-xs text-ellipsis ${
                      sortedArtifacts.length > 0
                        ? "text-green-500"
                        : "text-red-500"
                    }`}
                  >{`[${sortedArtifacts.length} Results]`}</span>
                </span>
              </summary>
              <div
                style={{
                  minHeight: sortedPrograms.length > 0 ? "5rem" : "10rem",
                }}
                className="flex flex-col gap-2 overflow-auto pt-0 "
              >
                {sortedArtifacts.map((programId) => (
                  <SearchItem
                    key={programId}
                    id={programId}
                    name={programId}
                    selected={selectedProgramId === programId}
                    onClick={() => {
                      if (selectedProgramId === programId) return;
                      onSelectProgramId(programId);
                    }}
                  />
                ))}
                {sortedArtifacts?.length === 0 && (
                  <div className="flex flex-1 flex-col items-center justify-center">
                    <SvgNotFound />
                    <p>No results found</p>
                  </div>
                )}
              </div>
            </details>
          )}
          <details
            id="section-2"
            key={"section-2"}
            open={openSection === "section-2"}
          >
            <summary
            // onClick={(e) => {
            //   e.preventDefault();
            //   // if (artifactIds.length === 0 && programs.length > 0) return;
            //   setOpenSection(
            //     openSection === "section-2" ? "section-1" : "section-2"
            //   );
            // }}
            >
              <span>
                {`${
                  metadata?.entityLabels?.[1] ||
                  metadata?.entityLabels?.[0] ||
                  "Programs"
                } `}
                <span
                  className={`text-xs text-ellipsis ${
                    sortedPrograms.length > 0
                      ? "text-green-500"
                      : "text-red-500"
                  }`}
                >{`[${sortedPrograms.length} Results]`}</span>
              </span>
            </summary>
            <div
              style={{
                minHeight: sortedPrograms.length > 0 ? "5rem" : "10rem",
              }}
              // style={{ height: `calc(100vh - ${progContainer}rem` }}
              className={`flex flex-col gap-2 overflow-auto pt-0`}
            >
              {sortedPrograms.map((programId) => (
                <SearchItem
                  key={programId}
                  id={programId}
                  name={programId}
                  selected={selectedProgramId === programId}
                  onClick={() => {
                    if (selectedProgramId === programId) return;
                    onSelectProgramId(programId);
                  }}
                />
              ))}
              {sortedPrograms?.length === 0 && (
                <div className="flex flex-1 flex-col items-center justify-center">
                  <SvgNotFound />
                  <p>No results found</p>
                </div>
              )}
            </div>
          </details>
        </div>
        {/* <div className="flex flex-col gap-2 overflow-auto p-4 pt-0 h-[calc(100vh-24rem)] ">
          {programs.map((programId) => (
            <SearchItem
              key={programId}
              id={programId}
              name={programId}
              selected={selectedProgramId === programId}
              onClick={() => {
                if (selectedProgramId === programId) return;
                onSelectProgramId(programId);
              }}
            />
          ))}
        </div> */}
      </div>
    </div>
  );
};

export { SideBar };
